/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const emailCheck = /* GraphQL */ `
  query EmailCheck($sessionId: String, $emailFrom: String, $emailTo: String) {
    emailCheck(sessionId: $sessionId, emailFrom: $emailFrom, emailTo: $emailTo)
  }
`;
export const totpVerify = /* GraphQL */ `
  query TotpVerify($sessionId: String, $code: String, $totpTime: String) {
    totpVerify(sessionId: $sessionId, code: $code, totpTime: $totpTime)
  }
`;
export const getPresignedUrl = /* GraphQL */ `
  query GetPresignedUrl(
    $region: String
    $bucket: String
    $key: String
    $upload_id: String
    $part_no: Int
  ) {
    getPresignedUrl(
      region: $region
      bucket: $bucket
      key: $key
      upload_id: $upload_id
      part_no: $part_no
    )
  }
`;
export const multipartComplete = /* GraphQL */ `
  query MultipartComplete(
    $region: String
    $bucket: String
    $key: String
    $parts: AWSJSON
    $upload_id: String
  ) {
    multipartComplete(
      region: $region
      bucket: $bucket
      key: $key
      parts: $parts
      upload_id: $upload_id
    )
  }
`;
export const getSession = /* GraphQL */ `
  query GetSession($sessionId: ID!) {
    getSession(sessionId: $sessionId) {
      sessionId
      emailFrom
      emailTo
      message
      otpTime
      uploadRegion
      uploadRegionFullname
      fileRetention
      tradeControlRelevant
      dlUrlToSender
      filename
      bucket
      uploadUrl
      expirationUnixTime
      files
      createdAt
      updatedAt
    }
  }
`;
export const getMp = /* GraphQL */ `
  query GetMp($sessionId: ID!, $filename: String!) {
    getMp(sessionId: $sessionId, filename: $filename) {
      sessionId
      filename
      uploadId
      key
      expirationUnixTime
      createdAt
      updatedAt
    }
  }
`;
