import React from 'react';

import './App.css';
import Dropzone from "./dropzone/Dropzone";
import LogoImg from "./images/logo_oerlikon.svg"

function App() {
  return (
    <div>
      <img src={LogoImg} className="logo" alt='cloudxs'/>
      <p className="title">Filetransfer Service</p>
      <div className="content-dropzone">
        <Dropzone />
      </div>
    </div>
  );
}

export default App;