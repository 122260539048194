import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';

import './Dropzone.css';
import LoaderImg from '../images/preloader.svg';

import { v4 as uuidv4 } from 'uuid';
import Amplify, { API } from "aws-amplify";
import * as gqlQueries from '../graphql/queries';
import { updateSession } from '../graphql/mutations';
import aws_exports from "../aws-exports";
import ask from './askrotreg.txt'; /* generated by lambda filetransferAPIkeyRotate */
const regionsSrc = require('../static/region.countrynames.json');
const prohibitedFiles = require('../static/prohibitedfilelist.json');


const Dropzone = () => {

    // maximum allowed file size by AWS = 5TB
    const MAX_FILE_SIZE = 5*(2**40);
    const MAX_PARALLEL_REQUESTS = 200;

    const additionalInfoRef = useRef();
    const fileInputRef = useRef();
    const progressRef = useRef();
    const progressBarRef = useRef();
    const uploadRef = useRef();
    const uploadModalRef = useRef();
    const otpModalRef = useRef();
    const otpRef = useRef();
    const okbtn = useRef();
    const messageModalRef = useRef();
    const messageRef = useRef();
    const ulBtnRef = useRef();
    //const [captchaValue, setCaptcha] = useState(false); //captcha disabled
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [validFiles, setValidFiles] = useState([]);
    const [unsupportedFiles, setUnsupportedFiles] = useState([]);
    const [otpTime, setOtpTime] = useState('');
    const [otp, setOtp] = useState('');
    const [tradeControlCheckbox, settradeControlCheckbox] = useState(false);
    const [dlUrlToSenderCheckbox, setdlUrlToSenderCheckbox] = useState(false);
      
    //Sendform settings
    var bgColors = { "default": "black",
                 "error": "red",
    };
    const [emailFromColor, setEmailFromColor] = useState (bgColors.default);
    const [emailToColor, setEmailToColor] = useState (bgColors.default);
    const [emailFrom, setEmailFrom] = useState ('');
    const [emailTo, setEmailTo] = useState ('');
    const [message, setMessage] = useState ('');
    const [uploadRegion, setUploadRegion] = useState ('');
    const [uploadRegionFullname, setUploadRegionFullname] = useState ('');
    const [uploadRegionSource, setUploadRegionSource] = useState([]);
    const [sessionId, setSessionId] = useState(uuidv4().toString());

    // set file retention select options (use h for hours and d for days)
    const fileRetention = [
        {'retention':'1d', 'label': 'available for 1 day'},
        {'retention':'3d', 'label': 'available for 3 days'},
        {'retention':'7d', 'label': 'available for 7 days'}
    ]
    const [selectedFileRetention,setSelectedFileRetention] = useState(fileRetention[0].retention);

    // function to sort an array alphabetically
    function sortArr(arr) {
        arr.sort((a, b) => {
            let fa = (typeof a === 'object') ? a.name.toLowerCase() : a.toLowerCase(),
                fb = (typeof b === 'object') ? b.name.toLowerCase() : b.toLowerCase();
            if (fa < fb) {return -1;}
            if (fa > fb) {return 1;}
            return 0;
        });
        return arr
    }

    useEffect(() => {

        // get regions
        setUploadRegionSource([{value: '', label: '(Select upload region)'}].concat(sortArr(regionsSrc).map((region) => {
            return {value: region.id, label: region.name}
        })));

        // grab appsync api key from txt file. It's in there so that it's easier to rotate the key
        fetch(ask).then(r => r.text()).then(key => {
            let config = aws_exports;
            config.aws_appsync_apiKey = key;
            Amplify.configure(config);
        });
    }, []);

    const validateEmailFrom = (event) => {
        // eslint-disable-next-line
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
        if (re.test(event.target.value))
        {
            setEmailFromColor(bgColors.default);
        } else {
            setEmailFromColor(bgColors.error);
        }
        setEmailFrom(event.target.value)
    }

    const validateEmailTo = (event) => {
        // eslint-disable-next-line
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
        if (re.test(event.target.value))
        {
            setEmailToColor(bgColors.default);
        } else {
            setEmailToColor(bgColors.error);
        }
        setEmailTo(event.target.value)
    }

    //Dropzone settings
    useEffect(() => {
        let filteredArr = selectedFiles.reduce((acc, current) => {
            const x = acc.find(item => item.name === current.name);
            if (!x) {
              return acc.concat([current]);
            } else {
              return acc;
            }
        }, []);
        setValidFiles([...filteredArr]);
        
    }, [selectedFiles]);

    const preventDefault = (e) => {
        e.preventDefault();
        // e.stopPropagation();
    }

    const dragOver = (e) => {
        preventDefault(e);
    }

    const dragEnter = (e) => {
        preventDefault(e);
    }

    const dragLeave = (e) => {
        preventDefault(e);
    }

    const fileDrop = (e) => {
        preventDefault(e);
        const files = e.dataTransfer.files;
        if (files.length) {
            handleFiles(files);
        }
    }

    const filesSelected = () => {
        if (fileInputRef.current.files.length) {
            handleFiles(fileInputRef.current.files);
        }
    }

    const fileInputClicked = () => {
        fileInputRef.current.click();
    }

    const handleFiles = (files) => {
        for(let i = 0; i < files.length; i++) {
            const isInvalid = isFileInvalid(files[i], i)
            if (isInvalid) {
                files[i]['errorMsg'] = isInvalid;
                setSelectedFiles(prevArray => [...prevArray, files[i]]);
                setUnsupportedFiles(prevArray => [...prevArray, files[i]]);
            } else {
                setSelectedFiles(prevArray => [...prevArray, files[i]]);
            }
        }
    }

    const isFileInvalid = (file, i) => {
        // check if the MIME type matches with placeholder, e.g. image/*
        // or if it matches exactly
        if ((prohibitedFiles.indexOf(file.type.substring(0,file.type.indexOf("/")+1)+"*" ) > -1) ||
            (prohibitedFiles.indexOf(file.type) > -1))    
        {
            return 'File type not permitted';
        }

        // check if file.size exeeds the maximum
        if (file.size > MAX_FILE_SIZE) {
            return `File size exceeds the maximum of ${fileSize(MAX_FILE_SIZE)}.`;
        }

        // check for special characters in filename
        const match = file.name.match(/&|!|%|,|;|:|¢|\+|"|=|\?|`|\^|'|´|~|{|}|\[|]|\(|\)|§|°|\||<|>|\/|\\/);
        if (match){
            return `Not allowed character in file name: ${match[0]}`;
        };

        return false;
    }

    const fileSize = (size) => {
        if (size === 0) {
          return '0 Bytes';
        }
        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        const i = Math.floor(Math.log(size) / Math.log(k));
        return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' +sizes[i];
    }

    const fileType = (fileName) => {
        return fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) || fileName;
    }

    const removeFile = (name) => {
        const index = validFiles.findIndex(e => e.name === name);
        const index2 = selectedFiles.findIndex(e => e.name === name);
        const index3 = unsupportedFiles.findIndex(e => e.name === name);
        validFiles.splice(index, 1);
        selectedFiles.splice(index2, 1);
        setValidFiles([...validFiles]);
        setSelectedFiles([...selectedFiles]);
        if (index3 !== -1) {
            unsupportedFiles.splice(index3, 1);
            setUnsupportedFiles([...unsupportedFiles]);
        }
    }

    const closeUploadModal = () => {
        uploadModalRef.current.style.display = 'none';
        //reload window to reset all components
        window.location.reload();
    }

    const closeOtpModal = () => {
        otpModalRef.current.style.display = 'none';
    }

    function showErrorModal(message) {
        messageModalRef.current.style.display = 'block';
        messageRef.current.innerHTML = `<span class="error">${message}</span>`;
        // reset sessionId to avoid conflicts
        setSessionId(uuidv4().toString())
    }

    function toggleUlBtn(){
        ulBtnRef.current.disabled = !ulBtnRef.current.disabled
        if (ulBtnRef.current.disabled){
            ulBtnRef.current.innerHTML = `<img width="57px" height="27px" src=${LoaderImg} alt="loading animation" />`
        } else {
            ulBtnRef.current.innerHTML = 'UPLOAD FILES'
        }
    }

    function toggleAdditionalInfo(e){
        if (additionalInfoRef.current.style.maxHeight === '0px'){
            additionalInfoRef.current.style.maxHeight = '500px'
            e.currentTarget.innerHTML = '&#x025B2; additional options'
        } else {
            additionalInfoRef.current.style.maxHeight = '0px'
            e.currentTarget.innerHTML = '&#x025BC; additional options'

        }
    }

    function uploadSpeed(bytes, milliseconds) {
        const s = (bytes*8) / (milliseconds/1000); //turns out in Bit/s
        const k = 1000;
        const speeds = ['bit/s', 'kbit/s', 'Mbit/s', 'Gbit/s'];
        const i = Math.floor(Math.log(s) / Math.log(k));
        return parseFloat((s / Math.pow(k, i)).toFixed(2)) + ' ' + speeds[i];
    }

    function preRunChecks() {
        //check if one of the mail adresses is empty
        if (!emailFrom || !emailTo){
            showErrorModal('Both e-mail addresses are required.')
            return
        };
        // check if both e-mail addresses are validated
        if(emailFromColor !== bgColors.default || emailToColor !== bgColors.default){
            showErrorModal('Please check the e-mail addresses.')
            return
        }
        if(!uploadRegion) {
            showErrorModal('Please select a region.')
            return
        };
        checkEmailDomain();
    }

    async function checkEmailDomain() {
        
        // disable upload buttn
        toggleUlBtn()
        
        //Check if E-Mail is from company
        let res = await API.graphql({query: gqlQueries.emailCheck, variables: {sessionId: sessionId, emailFrom: emailFrom, emailTo: emailTo}})
        const answer = JSON.parse(JSON.parse(res.data.emailCheck))
        if (answer.result !== "OK"){
            showErrorModal(answer.result)
            // reset sessionId to avoid conflicts
            setSessionId(uuidv4().toString())
        }
        else{

            // if emailCheck is ok, then try to get the corresponding otpTime from the DB. FYI the emailCheck lambda invokes the otp generation
            let session = null, retries = 0
            while(!session && retries < 20){
                session = await API.graphql({query: gqlQueries.getSession, variables: {sessionId: sessionId}})
                if(!session.data.getSession){
                    await new Promise(r => setTimeout(r, 500));
                    session = null
                    retries++
                    //console.log(`Retry: ${retries}`)
                }
            }
            
            if(session){
                setOtpTime(session.data.getSession.otpTime)

                progressBarRef.current.style.backgroundColor = '#eb0000';
                document.getElementById('otp').value = ''; //clear otp text input
                otpModalRef.current.style.display = 'block';
                otpRef.current.innerHTML = 'Please enter the six-digit code you received by e-mail.'

                //now, as the user waits for the email, the DB entry is updated with additional information, to provide this info to the lambda that creates the presigned url
                const dbEntry = {   sessionId: sessionId,
                                    uploadRegion: uploadRegion,
                                    uploadRegionFullname: uploadRegionFullname,
                                    fileRetention: selectedFileRetention,
                                    emailFrom: emailFrom,
                                    emailTo: emailTo,
                                    message: message,
                                    tradeControlRelevant: tradeControlCheckbox,
                                    dlUrlToSender: dlUrlToSenderCheckbox,
                                    files: validFiles.map(e => e.name).join(';')
                }
                API.graphql({query: updateSession, variables: {input: dbEntry} })
            }else{
                showErrorModal('Something went wrong with generating the TOTP code.')
            }
        }
        // enable upload buttn
        toggleUlBtn()
    }

    const verifyOtp = async () => {

        const resTotpVerify = await API.graphql({query: gqlQueries.totpVerify, variables: {sessionId: sessionId, code: otp, totpTime: otpTime}})
        const result = JSON.parse(resTotpVerify.data.totpVerify)

        closeOtpModal();
        if (result === "OK"){
            executeFileUpload();
        }
        else{
            showErrorModal(result)
        }
    }

    const executeFileUpload = async () => {
        uploadModalRef.current.style.display = 'block';
        progressBarRef.current.style.backgroundColor = 'red';
        okbtn.current.style.display = 'none';

        // wait for the UploadIds beeing available
        uploadRef.current.innerHTML = 'Initializing upload...';
        let mp = null, retries = 0
        while(!mp && retries < 80){
            mp = await API.graphql({query: gqlQueries.getMp, variables: {sessionId: sessionId, filename: validFiles[validFiles.length-1].name}}).catch(e => console.log(e));
            if(!mp || !mp.data || !mp.data.getMp || !mp.data.getMp.uploadId){
                await new Promise(r => setTimeout(r, 500));
                mp = null;
                retries++;
            }
        }

        // wait for the Session information beeing available
        let session = null
        retries = 0
        while(!session && retries < 20){
            session = await API.graphql({query: gqlQueries.getSession, variables: {sessionId: sessionId}}).catch(e => console.log(e));
            if(!session.data.getSession.sessionId){
                await new Promise(r => setTimeout(r, 500));
                session = null
                retries++
            }
        }
        
        // get multipart upload information for each file
        const mpuls = validFiles.map((item) => {
            return API.graphql({query: gqlQueries.getMp, variables: {sessionId: sessionId, filename: item.name}}).catch(e => console.log(e));
        });
        const mpUlSessions = await Promise.all(mpuls);

        // loop through all files
        let i = 0;
        let uploadResults = [];
        for (const file of validFiles){
            i++;
            const msgLine1 = `Process file <i>${file.name}</i> (${i}/${validFiles.length})`;
            let start = 0;
            let end = 0;
            let promisesPsUrls = [];
            let promisesPuts = [];
            let dataUploaded = {};
            // chunksize depending on max 10000 chunks and min chunksize of 5MB (AWS limits)
            let chunkSize = Math.max(Math.ceil(file.size/10000),(5*(2**20)));

            // get necessary amount of presigned Urls
            uploadRef.current.innerHTML = msgLine1 + '<br>Preparing upload URLs...'
            for (let i = 1; i <= Math.ceil(file.size/chunkSize); i++) {
                promisesPsUrls.push(MakeQuerablePromise(getPresignedUrl(i)));
                // Limit parallel requests. Wait a second if there are 
                // more pending promises than MAX_PARALLEL_REQUESTS
                while (promisesPsUrls.filter((promise) => promise.isPending() === true).length >= MAX_PARALLEL_REQUESTS) {
                    await new Promise(resolve => setTimeout(resolve, 1000));
                }
                //progressbar
                const uploadPercentage = Math.floor((i / Math.ceil(file.size/chunkSize)) * 100);
                progressBarRef.current.innerHTML = `${uploadPercentage}%`;
                progressBarRef.current.style.width = `${uploadPercentage}%`;
            }
            const psUrls = await Promise.all(promisesPsUrls);
            //reset
            progressBarRef.current.innerHTML = `0%`;
            progressBarRef.current.style.width = `0`;

            //uploadRef.current.innerHTML = msgLine1 + '<br>Uploading file...'
            // execute loop for each chunk
            let index = 0;
            const tsStart = Date.now();
            while (end < file.size){
                end = (start + chunkSize) < file.size ? (start + chunkSize) : file.size;
                // cut the file into chunks
                let blob = file.slice(start, end, file.type);
                const url = JSON.parse(psUrls[index].data.getPresignedUrl).body;

                let config = {
                    headers: { 'Content-Type': blob.type },
                    // eslint-disable-next-line no-loop-func
                    onUploadProgress: function(progressEvent) {
                        /* Each chunk adds its own amount of uploaed bytes to the object 'dataUploaded',
                        and then the sum of all chunks is used to calculate the percentage. */
                        dataUploaded[url]= progressEvent.loaded;
                        const sumUploaded = Object.values(dataUploaded).reduce((a, b) => a + b);
                        const uploadPercentage = Math.floor((sumUploaded / file.size) * 100);
                        /* the upload speed is an average of the overall upload process */
                        const speed = uploadSpeed(sumUploaded, (Date.now() - tsStart));

                        uploadRef.current.innerHTML = msgLine1 + `<br>Uploading file...<br><p style="font-size:0.7em">Uploaded: ${fileSize(sumUploaded)} of ${fileSize(file.size)}<br>Avg. speed: ${speed}</p>`;
                        progressBarRef.current.innerHTML = `${uploadPercentage}%`;
                        progressBarRef.current.style.width = `${uploadPercentage}%`;
                    }
                };
                // initiate the upload and put the promise into an array
                promisesPuts.push( MakeQuerablePromise(axios.put(url, blob, config)) );
                // Limit parallel requests
                while (promisesPuts.filter((promise) => promise.isPending() === true).length >= MAX_PARALLEL_REQUESTS) {
                    await new Promise(resolve => setTimeout(resolve, 1000));
                }

                // Set the start of the next chunk to the end of the current one.
                start = end;
                index++;
            }
            // wait until all chunks have been uploaded
            const putResults = await Promise.all(promisesPuts);

            // tell AWS that everything is uploaded and the file can be put together
            uploadRef.current.innerHTML = msgLine1 + '<br>Complete file upload...'
            const mpCmpltResponse = await multipartComplete();
            uploadResults.push({
                filename: file.name,
                HTTPStatusCode: JSON.parse(mpCmpltResponse.data.multipartComplete).body.ResponseMetadata.HTTPStatusCode
            });

            /* FUNCTIONS >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */

            function getPresignedUrl(index){
                const mpData = getMpData();
                return API.graphql({query: gqlQueries.getPresignedUrl, variables: {
                    region: session.data.getSession.uploadRegion,
                    bucket: session.data.getSession.bucket,
                    key: mpData.key,
                    upload_id: mpData.uploadId,
                    part_no: index
                }}).catch(e => console.log(e));
            }

            async function multipartComplete(){
                const parts = putResults.map((item,i) => {return {'ETag': JSON.parse(item.headers.etag), PartNumber: i+1 }});
                const mpData = getMpData()
                const response = API.graphql({query: gqlQueries.multipartComplete, variables: {
                    region: session.data.getSession.uploadRegion,
                    bucket: session.data.getSession.bucket,
                    key: mpData.key,
                    parts: JSON.stringify(parts),
                    upload_id: mpData.uploadId
                }});
                return await Promise.resolve(response).catch(e => console.log(e));
            }

            function getMpData(){
                //retrieve Upload information of the file
                const ulses = mpUlSessions.filter((se) => {return se.data.getMp.filename === file.name});
                return ulses[0].data.getMp;
            }

            function MakeQuerablePromise(promise) {
                // Don't modify any promise that has been already modified.
                if (promise.isResolved) return promise;
            
                // Set initial state
                var isPending = true;
                var isRejected = false;
                var isFulfilled = false;
            
                // Observe the promise, saving the fulfillment in a closure scope.
                var result = promise.then(
                    function(v) {
                        isFulfilled = true;
                        isPending = false;
                        return v; 
                    }, 
                    function(e) {
                        isRejected = true;
                        isPending = false;
                        throw e; 
                    }
                );
            
                result.isFulfilled = function() { return isFulfilled; };
                result.isPending = function() { return isPending; };
                result.isRejected = function() { return isRejected; };
                return result;
            }
        };

        // check if there was an error with one of the files
        // TODO: User richtig über diesen Fehler informieren
        const failedUploads = uploadResults.filter((item) => {return item.HTTPStatusCode !== 200});
        if (failedUploads.length > 0){
            console.error('Some files could not be uploaded:', failedUploads);
        }

        validFiles.length = 0;
        setValidFiles([...validFiles]);
        setSelectedFiles([...validFiles]);
        setUnsupportedFiles([...validFiles]);

        const availability = fileRetention.find((item) => {
            return item.retention === selectedFileRetention
        })

        uploadRef.current.innerHTML = `Upload completed successfully! You're all done.<br><br>Your file(s) are ${availability.label}.<br>An email will be sent to you and the recipient.`;
        okbtn.current.style.display = 'block';

        // reset sessionId to avoid conflicts
        setSessionId(uuidv4().toString())
    }

    return (
        <>
            <div className="send-form">
                <input 
                    className="formelement_basic"
                    type="email" 
                    id="send-from"
                    name="send-from" 
                    placeholder="Send from..."
                    onChange={validateEmailFrom}
                    style={{color: emailFromColor}}
                    required>
                </input>
                <input
                    className="formelement_basic"
                    type="email" 
                    id="send-to" 
                    name="send-to"
                    placeholder="Send to..."
                    onChange={validateEmailTo}
                    style={{color: emailToColor}}
                    required>
                    </input>
                <select className="regionselect formelement_basic" onChange={e => {
                    setUploadRegion(JSON.parse(e.currentTarget.value).region);
                    setUploadRegionFullname(JSON.parse(e.currentTarget.value).fullname);
                    }}>
                    {uploadRegionSource.map(({ label, value }) => (
                        <option key={value} value={JSON.stringify({"region":value, "fullname":label})}>{label}</option>
                    ))}
                </select>
                <div id="additionalInfo" className="formelement_basic">
                    <span id="additionalInfoToggle" onClick={e => toggleAdditionalInfo(e)}>&#x025BC; additional options</span>
                    <div ref={additionalInfoRef} style={{maxHeight:'0'}}>
                        <textarea 
                            className="msg-field formelement_basic"
                            id="message" 
                            name="message" 
                            placeholder="Message.."
                            maxLength="300"
                            onChange={e => setMessage(e.currentTarget.value)}>
                        </textarea>
                        <select className="formelement_basic" onChange={e => setSelectedFileRetention(e.currentTarget.value)}>
                            { fileRetention.map(({retention,label}) => (
                                <option key={retention} value={retention}>{label}</option>
                            ))}
                        </select>
                        <input type="checkbox" checked={tradeControlCheckbox} onChange={() => settradeControlCheckbox(!tradeControlCheckbox)} id="tradecontrol" name="tradecontrol" /><label htmlFor="tradecontrol"> trade-control relevant</label><br />
                        <input type="checkbox" checked={dlUrlToSenderCheckbox} onChange={() => setdlUrlToSenderCheckbox(!dlUrlToSenderCheckbox)} id="dlUrlToSender" name="dlUrlToSender" /><label htmlFor="dlUrlToSender"> receive download link</label>
                    </div>
                </div>
            </div>
            <div className="container">
                {unsupportedFiles.length ? <p>Please remove all unsupported files.</p> : ''}
                <div className="drop-container"
                    onDragOver={dragOver}
                    onDragEnter={dragEnter}
                    onDragLeave={dragLeave}
                    onDrop={fileDrop}
                    onClick={fileInputClicked}
                >
                    <div className="drop-message">
                        <div className="upload-icon"></div>
                        Drag & Drop files here or click to select file(s)
                    </div>
                    <input
                        ref={fileInputRef}
                        className="file-input"
                        type="file"
                        multiple
                        onChange={filesSelected}
                    />
                </div>
                <div className="file-upload-btn-container"style={{width: '100%', display: 'table'}}>{
                    unsupportedFiles.length === 0 && validFiles.length ? 
                    <div style={{display: 'table-row'}}>
                        <div style={{display: 'table-cell'}}>
                            <button className="file-upload-btn" onClick={() => preRunChecks()} ref={ulBtnRef} >UPLOAD FILES</button>
                        </div>
                        <div style={{display: 'table-cell'}} className="disclaimer">
                            <b>Please note:</b> The transfer of files containing export controlled technology requires prior approval 
                            of the responsible local trade control manager.
                        </div>
                    </div>
                     : 
                     ''}
                </div>
                <div className="file-display-container">
                    {
                        validFiles.map((data, i) => 
                            <div className="file-status-bar" key={i}>
                                <div onClick={data.errorMsg ? () => removeFile(data.name) : undefined}>
                                    <div className="file-type-logo"></div>
                                    <div className="file-type">{fileType(data.name)}</div>
                                    <span className={`file-name ${data.errorMsg ? 'file-error' : ''}`}>{data.name}</span>
                                    <span className="file-size">({fileSize(data.size)})</span> {data.errorMsg && <div className='file-error-message'>({data.errorMsg})
                                                                                                                {(data.errorMsg.indexOf('File type') > -1) && <span className='tooltiptext'>Prohibited file types:<br />{prohibitedFiles.map((val)=>{return val+`\n`})}</span>}
                                                                                                                </div>}
                                </div>
                                <div className="file-remove" onClick={() => removeFile(data.name)}>X</div>
                            </div>
                        )
                    }
                </div>
            </div>

            <div className="all-modal" ref={uploadModalRef}>
                <div className="overlay"></div>
                <div className="close" onClick={(() => closeUploadModal())}>X</div>
                <div className="progress-container">
                    <span ref={uploadRef}></span>
                    <div className="progress" ref={progressRef}>
                        <div className="progress-bar" ref={progressBarRef}></div>
                    </div>
                    <button className="ok-btn" onClick={(() => closeUploadModal())} ref={okbtn}>OK</button>
                </div>
            </div>

            <div className="all-modal" ref={otpModalRef}>
                <div className="overlay"></div>
                <div className="close" onClick={(() => closeOtpModal())}>X</div>
                <div className="otp-container">
                    <span ref={otpRef}></span>
                    <div className="otp">
                        <input
                            className="otp-textinput"
                            type="text"
                            id="otp" 
                            name="otp" 
                            placeholder="TOTP code"
                            maxLength="6"
                            minLength="6"
                            onChange={e => setOtp(e.currentTarget.value)}>
                        </input>
                        <button className="otp-btn" onClick={() => verifyOtp()}>Submit OTP</button>
                    </div>
                </div>
            </div>

            <div className="all-modal" ref={messageModalRef}>
                <div className="overlay"></div>
                <div className="close" onClick={(() => {messageModalRef.current.style.display = 'none';})}>X</div>
                <div className="progress-container">
                    <span ref={messageRef}></span>
                    <button className="ok-btn" onClick={(() => {messageModalRef.current.style.display = 'none';})}>OK</button>
                </div>
            </div>
        </>
    );
}

export default Dropzone;