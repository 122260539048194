/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const updateSession = /* GraphQL */ `
  mutation UpdateSession(
    $input: UpdateFiletransferSessionInput!
    $condition: ModelfiletransferSessionConditionInput
  ) {
    updateSession(input: $input, condition: $condition) {
      sessionId
      emailFrom
      emailTo
      message
      otpTime
      uploadRegion
      uploadRegionFullname
      fileRetention
      tradeControlRelevant
      dlUrlToSender
      filename
      bucket
      uploadUrl
      expirationUnixTime
      files
      createdAt
      updatedAt
    }
  }
`;
